import { authenticate } from '@chr/online-shared-microsite-core';

export const index = (async () => {
  const code = new URLSearchParams(window.location.search).get('code');

  if (code) {
    let appConfig;

    try {
      const response = await fetch('./app-config.json');
      appConfig = await response.json();

      await authenticate(appConfig);

      const uri = localStorage.getItem('NavisphereOnline.redirectURI');
      localStorage.removeItem('NavisphereOnline.redirectURI');

      if (uri) {
        window.location.assign(uri);
      }
    } catch (error) {
      console.error('Error:', error);
      return;
    }
  }
})();
